import React from "react";

import { PublicLayout } from "../components/layouts/PublicLayout";
import { ProviderWaitingValidationAuthentication } from "../containers/login/ProviderWaitingValidationAuthentication";

const OauthCallbackPage = () => {
  return (
    <PublicLayout path="/oauthCallback">
      <ProviderWaitingValidationAuthentication />
    </PublicLayout>
  );
};

export default OauthCallbackPage;
