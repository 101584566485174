import { useToast } from "@chakra-ui/react";
import { navigate, useLocation } from "@reach/router";
import React, { useEffect, useState } from "react";

import { $isuuid, $ok, $UUID } from "foundation-ts/commons";
import { Resp } from "foundation-ts/tsrequest";
import { UUID } from "foundation-ts/types";
import { ServiceSupplier } from "g1-commons/lib/doxecureTypes";

import { WaitingModal } from "../../components/Modals/WaitingModal";

import { config } from "../../config/config";
import { associateProviderChannel, getProviderUserInfo } from "../../services/authentication.service";
import { authProvidersDict } from "../../utils/Provider";
import { defaultErrorToast, defaultSuccessToast } from "../../utils/toast";

export const ProviderWaitingValidationAuthentication = () => {
    const [provider, setProvider] = useState<ServiceSupplier|null>() ;
    const [localState, setLocalState] = useState<string|null>() ;
    const [localNonce, setLocalNonce] = useState<string|null>() ;
    const [associationId, setAssociationId] = useState<UUID|null>() ;
    const toast = useToast() ;

    const checkValidOauthParameters = () => {
        return (state === localState) && $ok(localNonce) && $ok(provider) && $ok(authProvidersDict[provider!]) ;
    }

    const location = useLocation() ;
    const searchParams = new URLSearchParams(location.search) ;
    const state = searchParams.get("state") ;
    const code = searchParams.get("code") ;
    const err = searchParams.get("err") ;

    useEffect(() => {
        const associateProvider = async (associationId: UUID) => {
            if (await associateProviderChannel(associationId, provider!, code!, localNonce!)) {
                toast(defaultSuccessToast(`Votre compte a bien été associé à l'identité numérique ${provider?.capitalize()}`)) ;
            } else {
                toast(defaultErrorToast(`Impossible d'associer votre compte à l'identité numérique ${provider?.capitalize()}`)) ;
            }
            window.localStorage.clear() ;
            navigate("/app/userProfile/account") ;
        }

        setProvider(localStorage.getItem("provider") as ServiceSupplier) ;
        setLocalState(localStorage.getItem("state")) ;
        setLocalNonce(localStorage.getItem("nonce")) ;
        setAssociationId($UUID(localStorage.getItem("associationId"))) ;
        if (!$ok(state) || (!$ok(code) && !$ok(err))) {
            navigate(config.app.loginURL!) ;
        } else if ($ok(err)) {
            navigate(`${config.app.loginURL!}.${err}`) ;
        } else if (checkValidOauthParameters()) {
            if ($isuuid(associationId)) {
                associateProvider(associationId!) ;
            } else {
                getProviderUserInfo(provider!, code!, localNonce!).then(value => {
                    window.localStorage.clear() ;
                    navigate(value[1] === Resp.OK ? "/app/signatures" : `${config.app.loginURL!}?err=${value[1]}`) ;
                });
            }
        }
    }, [localState]) ;

    return <WaitingModal isOpen={true} onClose={function (): void {}} message="Validation des informations du compte sélectionné"></WaitingModal>
}